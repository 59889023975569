import { IUser } from '@shiftsmartinc/shiftsmart-types';
import { createContext } from 'react';

export interface GlobalAppContextShape {
  accessToken?: string;
  roles: IUser['roles'];
  timeZone: string;
  user?: IUser;
}

export const defaults: GlobalAppContextShape = {
  accessToken: undefined,
  roles: [],
  timeZone: 'America/New_York',
  user: undefined,
};

/**
 * @description This context stores some basic information that we need
 * to access throughout the application. We don't want to overload this
 * but it's a good place to store things that __ DO NOT __ change often.
 */
export const GlobalAppContext = createContext(defaults);
